import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, effect, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  AuroraHeaderI,
  AuroraLanguageModalI,
  AuroraMenuI,
  AuroraNewsletterI,
  AuroraStaticFooterI,
  AuroraStaticFooterV2I,
} from 'ctt-aurora';
import {
  catchError,
  EMPTY,
  filter,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { LayoutComponent } from './components/layout/layout.component';
import { environment } from '../environments/environment';
import { ApiService } from './services/api/api.service';
import {
  BodyComponent,
  PageConfig,
} from './services/interfaces/page-config.interface';
import { LayoutService } from './services/layout/layout.service';
import { MapperService } from './services/mapper/mapper.service';
import { SiteConfigService } from './services/site-config/site-config.service';
import { Utils } from './Utils/utils';

type GLOBAL_COMPONENTS =
  | AuroraNewsletterI
  | AuroraLanguageModalI
  | AuroraMenuI
  | AuroraStaticFooterI
  | AuroraStaticFooterV2I;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'structura-aurora';

  header$?: Observable<AuroraHeaderI>;

  globalComponents$?: Observable<
    BodyComponent<GLOBAL_COMPONENTS | undefined>[]
  >;

  languages$?: Observable<AuroraLanguageModalI>;
  newsletter$?: Observable<AuroraNewsletterI>;
  menu$?: Observable<AuroraMenuI>;

  constructor(
    private readonly siteSvc: SiteConfigService,
    private readonly apiService: ApiService,
    private readonly layoutSvc: LayoutService,
    private readonly mapperSvc: MapperService,
    private readonly renderer: Renderer2
  ) {
    effect(() => {
      this.setHeadConfig();
    });
  }

  ngOnInit(): void {
    this.getGlobalComponents();
    this.setGlobalComponentsData();
    this.getHeaderApi();
    this.getEngineApi();
  }

  getHeaderApi() {
    this.header$ = this.menu$?.pipe(
      switchMap((menu: AuroraMenuI | undefined) =>
        this.apiService.getMenu(this.siteSvc.selectedLanguageInstant?.id!).pipe(
          filter((data) => data !== null),
          map((data) => {
            const dataCamel = Utils.convertObjectKeysToCamelCase(data);
            return this.mapperSvc.mapHeader(
              dataCamel,
              menu ?? ({} as AuroraMenuI)
            );
          })
        )
      )
    );
  }

  getGlobalComponents(): void {
    this.globalComponents$ = this.siteSvc
      .getPageConfig<GLOBAL_COMPONENTS | undefined>(14, 0)
      .pipe(
        map((response) => {
          if (response instanceof HttpResponse) {
            return response.body;
          }
          return response;
        }),
        filter(
          (body): body is PageConfig<GLOBAL_COMPONENTS | undefined> =>
            body !== null
        ),
        map((response) => {
          const menuSocialMedia = (
            Utils.findComponent(response.body, 'socialmedia') as any
          )?.socialMedia;
          response.body = this.mapperSvc.mapComponents(response.body, {
            menuSocialMedia,
          });
          return response;
        }),

        tap((response) => {
          let footer = response.body.find((item: any) =>
            item.name.toLowerCase().includes('footerstatic')
          ) as any;

          this.layoutSvc.setFooter(footer);
        }),
        map((response) => response.body),
        take(1),
        shareReplay(1),
        catchError((err) => {
          return EMPTY;
        })
      );

    this.globalComponents$?.subscribe();
  }

  setGlobalComponentsData(): void {
    this.languages$ = this.globalComponents$?.pipe(
      filter((data) => data !== null && data !== undefined),
      map(
        (data) =>
          Utils.findComponent(data, 'languagesmodal') as AuroraLanguageModalI
      )
    );
    this.newsletter$ = this.globalComponents$?.pipe(
      filter((data) => data !== null && data !== undefined),
      map(
        (data) =>
          Utils.findComponent(data, 'newslettermodal') as AuroraNewsletterI
      )
    );
    this.menu$ = this.globalComponents$?.pipe(
      filter((data) => data !== null && data !== undefined),
      map((data) => Utils.findComponent(data, 'nav') as AuroraMenuI)
    );
  }

  getEngineApi(): void {
    this.apiService
      .getEngine(
        this.siteSvc.selectedLanguageInstant?.id,
        'site',
        environment.siteId
      )
      .pipe(
        filter((data) => data !== null),
        map((data) => {
          const dataCamel = Utils.convertObjectKeysToCamelCase(data);
          return this.mapperSvc.mapEngine(dataCamel);
        }),
        tap((data) => {
          this.layoutSvc.setEngineData(data);
        })
      )
      .subscribe();
  }

  setHeadConfig(): void {
    const config = this.layoutSvc.headInstant;

    if (config) {
      
      if (this.siteSvc.webConfigInstant?.favIcons?.length) {
        document.querySelectorAll("link[rel='icon']").forEach(favicon => {
          this.renderer.removeChild(document.head, favicon);
        });
    
        const faviconUrl = this.siteSvc.webConfigInstant.favIcons?.[1].toString() ?? 'assets/favicons/favicon.ico';
    
        const newFavicon = this.renderer.createElement('link');
        this.renderer.setAttribute(newFavicon, 'rel', 'icon');
        this.renderer.setAttribute(newFavicon, 'type', 'image/x-icon');
        this.renderer.setAttribute(newFavicon, 'href', faviconUrl);
    
        this.renderer.appendChild(document.head, newFavicon);
      }




      config.scripts?.forEach((script) => {
        document.head.innerHTML += script;
      });
      if (document.getElementsByTagName('title')[0]) {
        this.renderer.removeChild(
          document.head,
          document.getElementsByTagName('title')[0]
        );
      }
      const links = document.querySelectorAll('link');
      links.forEach((link) => {
        if (
          link.getAttribute('rel') === 'canonical' ||
          link.getAttribute('rel') === 'alternate'
        ) {
          this.renderer.removeChild(document.head, link);
        }
      });

      const metas = document.querySelectorAll('meta');
      metas.forEach((meta) => {
        if (
          meta.getAttribute('charset') !== 'utf-8' &&
          meta.getAttribute('name') !== 'viewport'
        ) {
          this.renderer.removeChild(document.head, meta);
        }
      });

      let group = '';

      config?.metas?.forEach((enlace: any) => {
        if (enlace['group'] !== group) {
          this.renderer.appendChild(
            document.head,
            this.renderer.createComment(enlace['group'].toUpperCase())
          );
          group = enlace['group'];
        }
        let linkElement: any;
        switch (enlace['type']) {
          case 'link':
            linkElement = this.renderer.createElement(
              'link'
            ) as HTMLLinkElement;
            linkElement.setAttribute('rel', 'preload');
            break;
          case 'meta':
            linkElement = this.renderer.createElement(
              'meta'
            ) as HTMLMetaElement;
            break;
          case 'title':
            linkElement = this.renderer.createElement(
              'title'
            ) as HTMLTitleElement;
            linkElement.text = enlace['content'];
            break;

          default:
            break;
        }
        if (enlace['type'] !== 'title') {
          Object.keys(enlace).forEach((key) => {
            if (key !== 'group' && key !== 'type') {
              this.renderer.setAttribute(linkElement, key, enlace[key]);
            }
          });
        }

        if (enlace['name'] !== 'viewport' && enlace['name'] !== 'charset') {
          this.renderer.appendChild(document.head, linkElement);
        }
      });
    }
  }
}
