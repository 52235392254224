<lib-aurora-preload></lib-aurora-preload>
@if (headerData && showheader) {
<lib-aurora-header
  [btnBook]="headerData.btnBook"
  [lang]="headerData.lang"
  [menu]="headerData.menu"
  [linkLogo]="headerData.linkLogo"
  [logo]="logos[0]"
  [logoSticky]="logos[1]"
  (openEngine)="openEngineModal()"
  (openLanguages)="openLanguagesModal()"
  (openNews)="openNewsletterModal()"
></lib-aurora-header>
}
<main>
  <ng-content></ng-content>
</main>

@if ($footer(); as footerData) { @if (footerData?.props; as props) {
<!--Defer when data loaded-->
@if (loadFooter) {
<!--choose the footer-->
@if (!footerV2) {
<ng-container
  *ngTemplateOutlet="footerStatic; context: { data: props }"
></ng-container>

} @else {
<ng-container
  *ngTemplateOutlet="footerStaticV2; context: { data: props }"
></ng-container>
} } } }

<!--HEADER MOBILE-->
@if (headerData) {
<lib-aurora-header-mobile
  [btnBook]="headerData.btnBook"
  [lang]="headerData.lang"
  [menu]="headerData.menu"
  (openEngine)="openEngineModal()"
  (openLanguages)="openLanguagesModal()"
  (openNews)="openNewsletterModal()"
></lib-aurora-header-mobile>

}

<ng-template #footerStatic let-data="data">
  <lib-aurora-static-footer
    #footer
    [img]="data.img"
    [title]="data.title"
    [ndTitle]="data.title"
    [rdTitle]="data.title"
    [address]="data.address"
    [contactLinks]="data.contactLinks"
    [links]="data.links"
    [socialMedia]="data.socialMedia"
    [copyright]="data.copyright"
    [legalAdvise]="data.legalAdvise"
    [input]="data.input"
  ></lib-aurora-static-footer>
</ng-template>

<ng-template #footerStaticV2 let-data="data">
  <lib-aurora-static-footer-v2
    #footerV2
    [imgs]="data.imgs"
    [title]="data.title"
    [ndTitle]="data.ndTitle"
    [ndTitle]="data.ndTitle"
    [rdTitle]="data.rdTitle"
    [fourthTitle]="data.fourthTitle"
    [address]="data.address"
    [contactLinks]="data.contactLinks"
    [links]="data.links"
    [socialMedia]="data.socialMedia"
    [copyright]="data.copyright"
    [buttons]="data.buttons"
  ></lib-aurora-static-footer-v2>
</ng-template>
