import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, NavigationSkipped, NavigationStart, Router } from '@angular/router';
import {
  AuroraEngineI,
  AuroraEngineModalComponent,
  AuroraEngineModalI,
  AuroraHeaderComponent,
  AuroraHeaderI,
  AuroraHeaderMobileComponent,
  AuroraLanguageModalComponent,
  AuroraLanguageModalI,
  AuroraLanguageModalItem,
  AuroraNewsletterComponent,
  AuroraNewsletterI,
  AuroraPreloadComponent,
  AuroraStaticFooterComponent,
  AuroraStaticFooterI,
  AuroraStaticFooterV2Component,
  AuroraStaticFooterV2I,
  ScreenSizerService,
} from 'ctt-aurora';
import { AuroraImageI } from 'ctt-aurora/lib/interfaces/aurora-image.interface';
import { catchError, EMPTY, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { BodyComponent } from '../../services/interfaces/page-config.interface';
import { LayoutService } from '../../services/layout/layout.service';
import { RoutesService } from '../../services/routes/routes.service';
import { SiteConfigService } from '../../services/site-config/site-config.service';

declare var $: any;

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    AuroraHeaderComponent,
    AuroraStaticFooterComponent,
    AuroraHeaderMobileComponent,
    AuroraStaticFooterV2Component,
    AuroraPreloadComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit {
  @ViewChild('footer', { read: ElementRef }) footerEl!: ElementRef;
  @ViewChild('footerV2', { read: ElementRef }) footerElV2!: ElementRef;

  @Input() headerData?: AuroraHeaderI;
  @Input() languagesData?: AuroraLanguageModalI;
  @Input() newsletterData?: AuroraNewsletterI;

  $footer = signal<
    BodyComponent<AuroraStaticFooterI | AuroraStaticFooterV2I> | undefined
  >(undefined);

  footerV2 = false;

  get logos(): AuroraImageI[] {
    return [
      {
        src: this.siteSvc.webConfigInstant?.logos?.[1] ?? '',
        alt: '',
      },
      {
        src: this.siteSvc.webConfigInstant?.logos?.[0] ?? '',
        alt: '',
      },
    ];
  }

  get loadFooter(): boolean {
    return this.layoutSvc.loadFooterInstant;
  }

  showheader: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly siteSvc: SiteConfigService,
    private readonly apiSvc: ApiService,
    private readonly layoutSvc: LayoutService,
    private readonly router: Router,
    private readonly routesSvc: RoutesService,
    private readonly screenSizer: ScreenSizerService,
    private readonly renderer: Renderer2,
    private readonly alert: MatSnackBar
  ) {
    effect(
      () => {
        if (this.layoutSvc.footer()) {
          const data = this.layoutSvc.footerInstant;

          this.$footer.set(data);
          if (data?.name.toLowerCase() !== 'footerstatic') {
            this.footerV2 = true;
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showheader = false;
      }
      if (event instanceof NavigationEnd) {
        this.layoutSvc.setLoadFooter(false);
        this.ViewControl();
      }
      if (event instanceof NavigationSkipped) {
        this.ViewControl();
      }
    });
    this.ViewControl() 
  }
  
  ViewControl() {
    const engineElements = document.querySelectorAll('lib-aurora-engine') as NodeListOf<HTMLElement>;
    engineElements.forEach(el => el.style.display = 'none');

    setTimeout(() => {
      this.showheader = true;
      engineElements.forEach(el => el.style.display = 'block');
    }, 0);
  }

  openLanguagesModal(): void {
    const dialogRef = this.dialog.open(AuroraLanguageModalComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      hasBackdrop: true,
    });
    const selectecEvent = new EventEmitter<AuroraLanguageModalItem>();

    selectecEvent.subscribe((lang) => {
      this.updateLanguage(lang);
    });

    dialogRef.componentInstance.title = this.languagesData?.title;
    dialogRef.componentInstance.subtitle = this.languagesData?.subtitle;
    dialogRef.componentInstance.items = this.languagesData?.items;
    dialogRef.componentInstance.selected = this.languagesData?.selected;

    dialogRef.componentInstance.languageSelect = selectecEvent;
  }

  openNewsletterModal(): void {
    const dialogRef = this.dialog.open(AuroraNewsletterComponent, {
      width: this.screenSizer.minMediumTablet() ? '65%' : '95vw',
      height: this.screenSizer.minMediumTablet() ? '80%' : '85vh',
      maxHeight: this.screenSizer.minMediumTablet() ? '714px' : '100vh',
      maxWidth: this.screenSizer.minMediumTablet() ? '1000px' : '100vw',
      hasBackdrop: true,
      panelClass: 'newsletter',
    });
    const submit = new EventEmitter<any>();

    submit
      .pipe(
        switchMap((email) => {
          return this.apiSvc
            .postNewsletterForm({
              siteId: environment.siteId,
              consultTypeId: 9,
              languageId: this.siteSvc.selectedLanguageInstant?.id,
              email,
            })
            .pipe(
              tap((response) => {
                this.alert.open((response as any).message, ' ', {
                  duration: 10000,
                  verticalPosition: 'top',
                });
                this.renderer.addClass(
                  document.getElementsByClassName(
                    'cdk-global-overlay-wrapper'
                  )[0],
                  'alert'
                );
                this.renderer.addClass(
                  document.getElementsByClassName('mdc-button__label')[0],
                  'aurora-plus'
                );
                dialogRef.close();
              }),
              catchError((err) => {
                this.alert.open(err.message, ' ', {
                  duration: 10000,
                  verticalPosition: 'top',
                });
                this.renderer.addClass(
                  document.getElementsByClassName(
                    'cdk-global-overlay-wrapper'
                  )[0],
                  'alert'
                );
                this.renderer.addClass(
                  document.getElementsByClassName('mdc-button__label')[0],
                  'aurora-plus'
                );
                this.renderer.addClass(
                  document.getElementsByClassName('mdc-snackbar__surface')[0],
                  'error'
                );
                return EMPTY;
              })
            );
        })
      )
      .subscribe();

    dialogRef.componentInstance.title = this.newsletterData?.title;
    dialogRef.componentInstance.subtitle = this.newsletterData?.subtitle;
    dialogRef.componentInstance.conditions = this.newsletterData?.conditions;
    dialogRef.componentInstance.input = this.newsletterData?.input;
    dialogRef.componentInstance.img = this.newsletterData?.img;
    dialogRef.componentInstance.submitForm = submit;
    dialogRef.componentInstance.button = this.newsletterData?.button;
  }

  updateLanguage(lang: AuroraLanguageModalItem): void {
    const selectedLanguage = this.siteSvc.webConfigInstant?.languages.find(
      (language) => Number(language.id) === Number(lang.id)
    );
    this.siteSvc.changeLanguage(selectedLanguage!);
    localStorage.setItem(
      'language',
      JSON.stringify(this.siteSvc.selectedLanguageInstant)
    );

    this.router.navigateByUrl(
      this.routesSvc.switchUrlDiferentLanguage(this.router.url) ?? ''
    );

    setTimeout(() => {
      location.reload();
    }, 200);
  }

  openEngineModal(): void {
    const dialogRef = this.dialog.open(AuroraEngineModalComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      hasBackdrop: true,
    });

    const closeEvent = new EventEmitter();

    closeEvent.subscribe(() => {
      dialogRef.close();
    });
    const data = this.layoutSvc.engineDataInstant as AuroraEngineI;

    dialogRef.componentInstance.datepickerInData = {
      ...data.datepickerData,
      placeholder: data.datepickerData?.placeholder?.split('-')[0],
    };
    dialogRef.componentInstance.datepickerOutData = {
      ...data.datepickerData,
      placeholder: data.datepickerData?.placeholder?.split('-')[1],
    };
    dialogRef.componentInstance.guestsInput = data.guestsInput;
    dialogRef.componentInstance.promoInput = data.promoInput;
    dialogRef.componentInstance.guests = data.guests;
    dialogRef.componentInstance.button = data.button;
    dialogRef.componentInstance.title = (data as AuroraEngineModalI).title;
    dialogRef.componentInstance.subtitle = (
      data as AuroraEngineModalI
    ).subtitle;

    dialogRef.componentInstance.close = closeEvent;
  }
}
