import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService, LoginResponse } from '../api/api.service';
import { WebConfig } from '../interfaces/site-config.interface';
import { RoutesService } from '../routes/routes.service';
import { SiteConfigService } from '../site-config/site-config.service';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private readonly siteSvc: SiteConfigService,
    private readonly routesSvc: RoutesService,
    private readonly apiService: ApiService
  ) {}

  loadSettings() {
    this.checkWWW();
    return new Promise<void>((resolve, _reject) => {
      this.getAccessToken()
        .pipe(
          switchMap(() => {
            this.siteSvc
              .getGeneralConfig(false)
              .pipe(
                filter(
                  (response): response is HttpResponse<WebConfig> | WebConfig =>
                    response !== null
                ),
                map((response) => {
                  if (response instanceof HttpResponse) {
                    return response.body;
                  }
                  return response;
                }),
                filter((body): body is WebConfig => body !== null),
                tap((config) => {
                  this.siteSvc.setWebConfig(config);
                  this.routesSvc.setSettingsRouter(config.pages);
                  this.loadExternalIcons(config?.css?.['icons']);
                  // this.googleMapsService.changeApiKey(
                  //   config.commonScripts["GoogleMapsKey"]!
                  // );
                  resolve();
                }),
                catchError((err: HttpErrorResponse) => {
                  //manage error
                  return EMPTY;
                })
              )
              .subscribe();

            return of(undefined);
          })
        )
        .subscribe();
    });
  }

  loadExternalIcons(suffixUrl?: string | null): void {
    if (suffixUrl) {
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${environment.baseUrl}/storage/css/icon.css`;
      document.head.appendChild(link);
    }
  }

  getAccessToken() {
    return this.apiService.postAuthLogin().pipe(
      filter((res): res is LoginResponse => res !== null),
      tap((res) => {
        environment.token = res.accessToken;
      }),
      catchError((err) => {
        //mostrar unhappy
        return EMPTY;
      })
    );
  }

  checkWWW(): void {
    if (
      !window.location.hostname.startsWith('www.') &&
      !window.location.hostname.includes('localhost')
    ) {
      const newUrl = `${window.location.protocol}//www.${window.location.hostname}${window.location.pathname}${window.location.search}`;
      window.location.replace(newUrl);
    }
  }
}
