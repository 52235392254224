import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { SCREEN_SIZER_PARAMS_TOKEN, ScreenSizerService } from 'ctt-aurora';
import { routes } from './app.routes';
import { InitializerService } from './services/initializer/initializer.service';

export function initSettings(initializer: InitializerService) {
  return (): Promise<any> => {
    return initializer.loadSettings();
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initSettings,
      deps: [InitializerService],
      multi: true,
    },
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideAnimations(),
    provideHttpClient(),
    ScreenSizerService,
    {
      provide: SCREEN_SIZER_PARAMS_TOKEN,
      useValue: {
        pixelBreakpoints: {
          mobile: 375,
          smallTablet: 540,
          mediumTablet: 768,
          fullTablet: 1024,
          smallDesktop: 1280,
          mediumDesktop: 1366,
          fullDesktop: 1680,
          fullBigDesktop: 1800,
          maxDesktop: 1920,
        },
      },
    },
  ],
};
