@if (header$ | async; as headerData) {
<app-layout
  [headerData]="headerData"
  [languagesData]="(languages$ | async)!"
  [newsletterData]="(newsletter$ | async)!"
>
  <router-outlet></router-outlet>
</app-layout>

}
